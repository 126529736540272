import useThemeController from "aldoo-ra/ThemeController"
import React from "react"
import Value from "aldoo-ra/Value"
import HeaderSection from "@components/Site/IndexPage/Ios/HeaderSection"
import TypewriterSection from "@components/Site/TypewriterSection"
import FeaturesAndBenefits from "@components/Site/Features/FeaturesAndBenefits"
import TestimonialsSection from "@components/Site/TestimonialsSection"
import HomeFeatureCardsSection from "../../components/Site/HomeFeatureCardsSection"
import OrganizedBanner from "@components/Site/OrganizedBanner"
import Faq from "@components/Site/Faq"
import BubblesBg from "@components/Site/BubblesBg/BubblesBg"
import NewsletterSubscribeSection from "@components/Site/NewsletterSubscribeSection"
import LatestBlogArticlesSection from "@components/Site/LatestBlogArticlesSection"
import PlatformSelector from "@components/Site/PlatformSelector"

const iOSHome = () => {
  const [isTransitioning] = Value("page_transition")
  useThemeController()

  return (
    <div className="relative">
      <PlatformSelector />
      <div
        className={`overflow-hidden transition-opacity duration-300 ${
          isTransitioning ? "opacity-0" : "opacity-100"
        }`}
      >
        <HeaderSection />
        <TypewriterSection />
        <HomeFeatureCardsSection />
        <FeaturesAndBenefits />
        <TestimonialsSection />
        <OrganizedBanner />
        <Faq />
        <LatestBlogArticlesSection />
        <NewsletterSubscribeSection />
        <BubblesBg variant="variant1" />
      </div>
    </div>
  )
}

export default iOSHome
