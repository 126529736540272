import React from "react"
import HeaderSectionLeft from "./HeaderSectionLeft.jsx"
import HeaderSectionRight from "./HeaderSectionRight.jsx"
import CMSData from "aldoo-ra/CMS/cms-data"

const HeaderSection = () => {
  //Load the data for the section from the CMS
  const { data, loading } = CMSData({
    contentType: "PageSection",
    match: { "content.name": "iOSHomeHeader" },
  })

  if (loading) return

  // All classes are the same for all platforms to ensure same layout
  return (
    <div className="w-full px-6 md:px-[48px] lg:px-[56px] xl:px-[96px]">
      <div className="relative flex flex-col md:flex-row w-full md:max-w-[800px] lg:max-w-[900px] xl:max-w-[1380px] 2xl:max-w-w1_xl justify-between items-center md:items-start gap-6 2xl:gap-0 pt-8 xl:pt-0 mx-auto">
        <HeaderSectionLeft data={data[0]} />
        <HeaderSectionRight data={data[0]} />
      </div>
    </div>
  )
}

export default HeaderSection
