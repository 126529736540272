import React from "react"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import parse from "html-react-parser"

const HeaderSectionLeft = ({ data }) => {
  if(!data) return null
  return (
    <div className="flex flex-col w-full md:w-[60%] xl:w-[680px] 2xl:w-[850px] xl:flex-shrink-0 justify-start items-start">
      <div className="flex flex-col w-full max-w-[800px] items-center md:items-start md:pt-7 lg:pt-10 xl:pt-16 2xl:pt-[120px]">
        {/* Title */}
        <Typography
          component="h1"
          variant="title2"
          className="w-full text-center md:text-left mb-4"
        >
          {parse(data.title)}
        </Typography>

        {/* Subtitle: wrapped in a div with equal height for both platforms (md and above), to ensure same positioning of the buttons below when the text goes in 2 lines */}
        <div className="w-full max-w-[300px] md:max-w-[400px] lg:max-w-full md:h-[100px] lg:h-[85px] xl:h-[85px] mb-7 xl:mb-5">
          <Typography
            variant="subtitle_signature"
            className="text-center md:text-left mb-0"
          >
            {data.description}
          </Typography>
        </div>

        {/* CTA Buttons and Statistics */}
        <div className="flex flex-col-reverse md:flex-col items-start gap-8 md:gap-10 lg:gap-[56px]">
          <div className="flex items-center gap-6">
            {data.menu?.map((item, index) => {
              item.enabled
              if ( !item.enabled ) return
              const variant = index === 0 ? "primary_accent_xl" : "secondary_xl"
              const url = item?.link ? item.link[0].url : ""

              return (
                <Button key={index} variant={variant}>
                  <a href={url} target="_blank">
                    {item?.label}
                  </a>
                </Button>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderSectionLeft
